import React from "react";
import QRCode from 'qrcode.react';
import copy_url_logo from "../../assets/copy_url.svg";
import back_logo from "../../assets/left_sq_bracket.svg";

const QRSidebar = ({ docId, isQRCodeOpen, ToggleQRSidebar }) => {

    const size = 200;
    const bgColor = "#eee";
    const fgColor = "#5f1587";

    const CopyToClipboard = (elementId) => {
        var text = document.getElementById(elementId).innerHTML;
        navigator.clipboard.writeText(text);
    }

    return (
        <>
            <div className={`user-sidebar ${isQRCodeOpen === true ? 'active' : ''}`}>
                <div className="usd-header">
                    <h3 className="mb-0">Session Details</h3>
                    <img src={back_logo} className="userlist-back-btn" alt="back" onClick={ToggleQRSidebar} />
                </div>
                <div className="usd-body">
                    <QRCode
                        style={{
                            margin: '0 auto',
                            display: 'block',
                        }}

                        size={size}
                        level='M'
                        includeMargin={true}
                        renderAs="svg"
                        bgColor={bgColor}
                        fgColor={fgColor}
                        value={docId}
                    />
                    <br />
                    <br />
                    <p className="p-url">
                        <span className="inst-down-links">User Session id : </span>
                        <span id='copysessid-sb' className="link-col">{docId}</span>
                        <span onClick={() => CopyToClipboard('copysessid-sb')}>
                            <img className="copy-url mb-2 ms-1" src={copy_url_logo} alt="copy_session_id" />
                        </span>
                    </p>
                    <p className="p-url">
                        <span className="inst-down-links">User Session link : </span>
                        <span id='copysesslink-sb' className="link-col">{window.location.origin + "/join-session/" + docId}</span>
                        <span onClick={() => CopyToClipboard('copysesslink-sb')}>
                            <img className="copy-url mb-2 ms-1" src={copy_url_logo} alt="copy_session_url" />
                        </span>
                    </p>
                    <p className="p-url">
                        <span className="inst-down-links">Presenter link : </span>
                        <span id='copypreslink-sb' className="link-col">{window.location.origin + "/presenter/" + docId}</span>
                        <span onClick={() => CopyToClipboard('copypreslink-sb')}>
                            <img className="copy-url mb-2 ms-1" src={copy_url_logo} alt="copy_presenter_url" />
                        </span>
                    </p>
                </div>
            </div>
            <div className={`user-sidebar-overlay ${isQRCodeOpen === true ? 'active' : ''}`} onClick={ToggleQRSidebar}></div>
        </>
    )
}

export default QRSidebar;