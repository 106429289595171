import React, { useState, useEffect, useContext } from "react";
import { onSnapshot, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { saveAs } from 'file-saver';
import { StoreContext } from "../../context/StoreProvider";
import PageView from "../PageUtilComponents/PageView";
import PageHeader from "../PageUtilComponents/PageHeader";
import ScreenLoader from "../HelperComponents/ScreenLoader";
import download_logo from "../../assets/download.svg";

const sanitizeDashFileName = (name) => {
    if (typeof name !== 'string') {
        console.error('Invalid filename provided:', name);
        return 'Whitepad-session';
    }
    return name.replace(/[^a-zA-Z0-9-_]/g, '-');
};

const Board = ({ DocId, sessName }) => {

    const navigate = useNavigate();
    const { handlePDFDownload } = useContext(StoreContext);
    const [docError, setDocError] = useState('');
    const [userError, setUserError] = useState('');
    const [tempUserName, setTempUserName] = useState(null);
    const [endUserStatus, setEndUserStatus] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [isPause, setIsPause] = useState(true);
    const [imgUrl, setImgUrl] = useState('');
    const [endUserName, setEndUserName] = useState(() => {
        const initialValue = localStorage.getItem("endUser");
        return initialValue || null;
    });

    const deleteUserFromLS = () => {
        localStorage.removeItem("endUser");
        setEndUserName(null);
    }

    const verifyNewUser = (e, vUserName) => {
        e.preventDefault();

        localStorage.setItem("endUser", vUserName)

        const endUserRef = doc(db, "end_users", DocId);
        // check if user exist
        getDoc(endUserRef).then(docSnap => {
            if (docSnap.exists()) {
                let listUser = docSnap.data();

                let approved = listUser.approved;
                let pending = listUser.pending;
                let denied = listUser.denied

                let found = false;
                let index = pending.indexOf(vUserName);

                if (index === -1) {
                    index = denied.indexOf(vUserName)

                    if (index === -1) {
                        index = approved.indexOf(vUserName)

                        if (index !== -1) {
                            found = true;
                        }
                    } else {
                        found = true;
                    }
                } else {
                    found = true;
                }

                if (found) {
                    setUserError('Please use another username')
                } else {
                    pending.push(vUserName);
                    setUserError('')
                    setEndUserName(vUserName);
                    updateDoc(endUserRef, {
                        "pending": pending
                    });
                }
            }
        })
    };

    const generatePDF = async (sname) => {
        setShowLoader(true);
        const response = await handlePDFDownload(DocId, imgUrl);
        if (response.success) {
            setShowLoader(false);
            saveAs(response.data, sanitizeDashFileName(sname));
        } else {
            setShowLoader(false)
        }
    };

    useEffect(() => {
        if (endUserStatus) {
            let firstFlag = true;
            const unsub = onSnapshot(doc(db, "board_session", DocId), { source: 'server' }, (data) => {
                console.log('image snapshot triggered')
                if (data.exists) {
                    setDocError('');
                    const docData = data.data();
                    setIsPause(docData.pause);
                    if (docData.page_id === 0) {
                        if (!isPause || firstFlag) {
                            setImgUrl(docData.data_base64);
                            firstFlag = false;
                        }
                    } else {
                        setImgUrl(docData.page_src);
                        setIsPause(false)
                    }
                } else {
                    setDocError('Please enter a valid session key');
                }
            });

            return () => unsub();
        }

    }, [DocId, isPause, endUserStatus]);

    useEffect(() => {

        const unsub = onSnapshot(doc(db, "end_users", DocId), { source: 'server' }, (docData) => {
            console.log('users snapshot triggered')
            if (docData.exists) {
                setUserError('')
                let listUser = docData.data();
                if (listUser) {
                    let approved = listUser.approved;
                    let index = approved.indexOf(endUserName)

                    if (index !== -1) {
                        setEndUserStatus(true);
                    } else {
                        setEndUserStatus(false);
                    }
                }
            } else {
                setUserError('Error fetching user list')
            }
        });

        return () => unsub();

    }, [DocId, endUserName]);

    return (showLoader ?
        <>
            <ScreenLoader placeMiddle={true} />
            {(() => {
                setTimeout(() => {
                    setShowLoader(false);
                }, 1500);
                return null;
            })()}
        </>
        :
        <>
            {docError ?
                <>
                    <div className="info-toast info-toast-bottom">
                        <h3>{docError}</h3>
                        <span style={{ color: "#842029", cursor: "pointer" }} onClick={() => navigate('/join-session')}>Click here to re-enter session ID</span>
                    </div>
                </>
                :
                <>
                    {endUserStatus ?
                        <>
                            <div className="download-action" onClick={() => generatePDF(sessName)}>
                                <img src={download_logo} className="plusActionBtn" alt="download" />
                            </div>
                            <div className="p5 m-auto d-flex flex-column align-items-center">
                                <div className={`session-card ${imgUrl ? 'mw-100' : ''}`}>
                                    <PageHeader sessName={sessName} />
                                    <PageView imgUrl={imgUrl} isPause={isPause} canFS={true} />
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {userError &&
                                <>
                                    <div className="info-toast info-toast-bottom start-50 translate-middle info-toast-no-unserline">
                                        <span>{userError}</span>
                                    </div>
                                </>
                            }

                            {endUserName ?
                                <>
                                    <div className="info-toast top-50 start-50 translate-middle info-toast-no-unserline" style={{borderRadius: '6px'}}>
                                        {endUserName}, Please wait till the admin approves you request.<br /><br /> If {endUserName} is not you, Please <span className="text-decoration-none link-col" onClick={() => deleteUserFromLS()}>click here</span> to reset.
                                    </div>
                                </>
                                :
                                <div className="p5 flex-column align-items-center">
                                    <h2>Join an ongoing session</h2>
                                    <p>Please enter the session id below to join a session.</p>
                                    <br />
                                    <div>
                                        <form onSubmit={(e) => verifyNewUser(e, tempUserName)}>
                                            <input
                                                type="text"
                                                placeholder="Please enter your username"
                                                onChange={(e) => setTempUserName(e.target.value)}
                                                style={{
                                                    outline: (userError ? '2px solid #ff0000' : '0 !important'),
                                                    borderColor: '#480161',
                                                    minWidth: '300px'
                                                }}
                                                className="mb-3 form-control create-join-session-input"
                                                required
                                            />
                                            <Button type="submit" className='btn-primary create-join-session-btn'>Request</Button>
                                        </form>
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                            }
                        </>
                    }
                </>
            }
        </>
    );
}

export default Board;
