import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';

const PageNotFound = () => {

    const navigate = useNavigate();

    return (
        <>
            <section className="page-not-found-section">
                <div className="container error-container">
                        <h2>I have bad news for you - 404 Not Found</h2>
                        <p>
                            The page you are looking for might be removed or is temporarily
                            unavailable
                        </p>
                        <Button className='me-3' onClick={() => navigate("/")}>Back to homepage</Button>
                </div>
            </section>
        </>
    );
}

export default PageNotFound;