const PlusActions = ({ actions }) => {

	return (
		<ul
			className="fab-container"
		>
			{actions.map((action, index) => (
				<li
					style={{ transitionDelay: `${index * 25}ms` }}
					key={action.label}
					className="fab-action"
					onClick={action.onClick}
				>
					{action.icon}
					<span className="tooltip">{action.label}</span>
				</li>
			))}
		</ul>
	);
};

export default PlusActions;