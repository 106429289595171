import React from 'react'
import { Link } from 'react-router-dom';

function InstrunctionsDetails() {
    return (
        <div className="instructions-details">
            <h4>Getting Started</h4>
            <ol>
                <li>
                    <strong>Create an Account:</strong>
                    <ul>
                        <li>Visit <Link className="inst-down-links" to='/login'>sushboard.com/login</Link> and create a free trial account</li>
                        <li>Sign in using your Google account</li>
                    </ul>
                </li>
                <li>
                    <strong>Download the App:</strong>
                    <ul>
                        <li>Available on the Play Store.</li>
                        <li>Scan the QR code or visit <Link className="inst-down-links" to='/download'>sushboard.com/download</Link></li>
                    </ul>
                </li>
            </ol>

            <h4>Creating a Session</h4>
            <ol>
                <li>
                    <strong>Start a Session:</strong>
                    <ul>
                        <li>Hit "Create Session"  to create a new session</li>
                        <li>Scan the QR code with the app and share the session ID or link with your students</li>
                    </ul>
                </li>
                <li>
                    <strong>Start Streaming:</strong>
                    <ul>
                        <li>Mount your phone over a stand with a clear view of the page</li>
                        <li>Start the session and begin streaming</li>
                    </ul>
                </li>
                <li>
                    <strong>Join a Session:</strong>
                    <ul>
                        <li>Students can use the "Join Session" button or link and add their name</li>
                        <li>The tutor then grants access to join the session</li>
                    </ul>
                </li>
            </ol>
        </div>
    )
}

export default InstrunctionsDetails