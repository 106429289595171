import React, { useState } from 'react';
import { Dropdown } from "react-bootstrap";

const PageDropdown = ({ options, ddSelector, pageId }) => {

    const [selectedPage, setSelectedPage] = useState('Live Page');

    const handleDropdownChange = (option) => {
        setSelectedPage(option.page_name);
        ddSelector(option);
    };

    return (
        <>
            <Dropdown className="page-dd" data-bs-theme="dark">
                <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                    {selectedPage}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {options.map((option) => (
                        <Dropdown.Item
                            key={option.id}
                            active={option.id === pageId}
                            onClick={() => handleDropdownChange(option)}
                        >
                            {option.page_name}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

export default PageDropdown;