import { useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const LoginWithGoogle = () => {
	const { loading, user, googleLogin, UpdateUserInDB } = useContext(AuthContext);
	const navigate = useNavigate();

	// If authentication is still loading, display a loading indicator
	if (loading) {
		return (
			<span className="loading loading-dots loading-lg flex item-center mx-auto"></span>
		);
	}

	// If the user is already authenticated, redirect to the home page
	if (user) {
		navigate("/");
	}

	const handleGoogleLogin = () => {
		googleLogin()
			.then((retVal) => {
				console.log('successfull login')
				UpdateUserInDB(retVal.user, '')
				navigate("/");
			})
			.catch((error) => {
				console.log(error);
			});
	}

	// Render the login form
	return (
		<>
			<div className="form-control mt-6 login-submit">
				<Button className="btn btn-primary form-box" onClick={() => handleGoogleLogin()}>Login with Google</Button>
			</div>
		</>
	);
};

export default LoginWithGoogle;