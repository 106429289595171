import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    signInWithPopup,
} from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { auth, gauthprovider } from "../config/firebase-config";
import axios from "axios";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const createUser = (email, password) => {
        setLoading(true);
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const loginUser = (email, password) => {
        setLoading(true);
        return signInWithEmailAndPassword(auth, email, password);
    };

    const logOut = () => {
        setLoading(true);
        setUser(null);
        return signOut(auth);
    };

    const forgotPassword = (email) => {
        setLoading(false);
        return sendPasswordResetEmail(auth, email);
    };

    const googleLogin = () => {
        setLoading(true);
        return signInWithPopup(auth, gauthprovider);
    };

    const UpdateUserInDB = (userDetails, displayName = '') => {
        if (displayName === '') {
            displayName = userDetails.displayName;
        }

        return axios({
            method: 'post',
            baseURL: process.env.REACT_APP_API_BASE_URL,
            url: '/update-customer',
            auth: {
                username: process.env.REACT_APP_API_USERNAME,
                password: process.env.REACT_APP_API_PASSWORD,
            },
            params: {
                auth_uid: userDetails.uid,
                email: userDetails.email,
                display_name: displayName,
            },
        })
        .then((response) => {
            if (!(response.data === true || response.data === false)) {
                console.log('user updation successful');
                return true;
            } else {
                return false;
            }
        })
        .catch((error) => {
            if (error.response) {
                console.error('Server Error:', error.response.status);
            } else if (error.request) {
                console.error('Network Error:', error.request);
            } else {
                console.error('Error:', error.message);
            }
            return false;
        });
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                // Fetch additional user data from your API
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-customer-data`, {
                        auth: {
                            username: process.env.REACT_APP_API_USERNAME,
                            password: process.env.REACT_APP_API_PASSWORD,
                        },
                        params: {
                            uid: firebaseUser.uid,
                        },
                    });

                    // Combine Firebase user data with additional data
                    const respData = response.data;
                    const additionalData = respData.data;
                    setUser({ ...firebaseUser, ...additionalData });
                } catch (error) {
                    console.error('Failed to fetch additional user data:', error);
                    setUser(firebaseUser); // Fallback to only Firebase data if fetching fails
                }
            } else {
                setUser(null);
                signOut(auth);
            }
            setLoading(false);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const authValue = {
        createUser,
        user,
        loginUser,
        logOut,
        loading,
        forgotPassword,
        googleLogin,
        UpdateUserInDB,
    };

    return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthProvider;