import { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { StoreContext } from "../../context/StoreProvider";
import { AuthContext } from "../../context/AuthProvider";
import Board from "./Board";
import ScreenLoader from "../HelperComponents/ScreenLoader";

const invalidMsg = "The entered session key is invalid";

const JoinSession = () => {
    const { validateSession } = useContext(StoreContext);
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const params = useParams();
    const paramId = params.id;
    const [join, setJoin] = useState();
    const [error, setError] = useState();
    const [docId, setDocId] = useState(null);
    const [sessName, setSessName] = useState('');
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        if (paramId) {
            const validate = async () => {
                const response = await validateSession(paramId);
                if (response.success) {
                    const sessData = response.data;
                    setSessName(sessData.session_name)
                    if (user) {
                        if (sessData.cust_id === user.db_id) {
                            navigate(`/presenter/${paramId}`);
                        }
                    }
                    setDocId(paramId);
                    setShowLoader(false)
                } else {
                    setError(invalidMsg);
                    setShowLoader(false)
                }
            };
            validate();
        }
    }, [paramId, validateSession, user, navigate]);

    const handleJoinSession = async (e) => {
        e.preventDefault();

        if (join) {
            const response = await validateSession(join);
            if (!response.success) {
                setError(response.error);
            } else {
                if (user) {
                    const sessData = response.data;
                    if (sessData.cust_id === user.db_id) {
                        navigate(`/presenter/${join}`);
                    }
                }
                navigate(`/join-session/${join}`);
            }
        } else {
            setError('Please enter session key in the input field.');
        }
    }

    return (showLoader ?
        <>
            <ScreenLoader placeMiddle={true} />
            {(() => {
                setTimeout(() => {
                    setShowLoader(false);
                }, 1500);
                return null;
            })()}
        </>
        :
        <>
            {error ?
                <>
                    <div className="info-toast top-50 start-50 translate-middle info-toast-no-unserline">
                        <span>{error}</span>
                        <span style={{ color: "rgb(13,110,253)", cursor: "pointer" }} onClick={() => setError('')}>Close</span>
                    </div>
                </>
                :
                <section className="pageModal join-section">
                    <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-12 ">

                                {docId ? <Board DocId={docId} sessName={sessName} /> :
                                    <>
                                        <h2>Join an ongoing session</h2>
                                        <p>Please enter the session id below to join a session.</p>
                                        <br />
                                        <div>
                                            <form onSubmit={(e) => handleJoinSession(e)}>
                                                <input
                                                    type="text"
                                                    placeholder="Please enter a session key"
                                                    onChange={(e) => setJoin(e.target.value)}
                                                    style={{
                                                        outline: (error ? '1px solid #ff0000' : '0 !important'),
                                                        minWidth: '300px'
                                                    }}
                                                    className="mb-3 form-control create-join-session-input"
                                                    required
                                                />
                                                <Button type="submit" className='btn-primary create-join-session-btn'>Join Session</Button>
                                            </form>
                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    );
}

export default JoinSession;
