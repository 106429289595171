import React, { useState, useEffect, useContext  } from 'react';
import CreateNormalSession from "./CreateNormalSession";
import CreateColabSession from "./CreateColabSession";
import GroupsManager from "./GroupsManager";
import ScreenLoader from "../HelperComponents/ScreenLoader";
import { AuthContext } from "../../context/AuthProvider";
import { ReactComponent as BackLogo } from "../../assets/left_sq_bracket.svg";

function SessionManager() {

    const { user, loading } = useContext(AuthContext);
    const [activeComponent, setActiveComponent] = useState('normal-session');
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleLinkClick = (link) => {
        setActiveComponent(link);
        if (window.innerWidth < 769) {
            toggleSidebar();
        }
    }

    const renderComponent = () => {
        switch (activeComponent) {
            case 'normal-session':
                return <CreateNormalSession user={user} />;
            case 'collab-session':
                return <CreateColabSession user={user} />;
            case 'groups-manager':
                return <GroupsManager user={user} />;
            default:
                return <CreateNormalSession user={user} />;
        }
    };

    useEffect(() => {
        if (user) {
            if (user.db_is_valid === 1 || user.db_is_in_trial === true) {
                setHasAccess(true)
            } else {
                setHasAccess(false)
            }
        }
    }, [user])

    return loading ? (
        <ScreenLoader placeMiddle={true} />
    ) : ( 
        <>
            { !hasAccess ? 
                <>
                    <div className='p5 m-auto mt-5 pt-5 d-flex flex-column align-items-center'>
                        <p>The current logged in use does not have privilages to Session Manager</p>
                        <p>Please speak with the administrator</p>
                        <br />
                        <br />
                    </div>
                </>
                :
                <section className="sm-section">
                    <nav className={`sm-sidebar ${sidebarOpen ? 'open' : ''}`}>
                        <button className="close-btn" onClick={toggleSidebar}>
                            <BackLogo fill="white" className="userlist-back-btn" alt="back" />
                        </button>
                        <ul>
                            <li>
                                <button
                                    className={`nav-link ${activeComponent === 'normal-session' ? 'active-link' : ''}`}
                                    onClick={() => handleLinkClick('normal-session')}
                                >
                                    {activeComponent === 'normal-session' ? '-' : ''} Normal Session
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`nav-link ${activeComponent === 'collab-session' ? 'active-link' : ''}`}
                                    onClick={() => handleLinkClick('collab-session')}
                                >
                                    {activeComponent === 'collab-session' ? '-' : ''} Collaborative Session
                                </button>
                            </li>
                            <li>
                                <button
                                    className={`nav-link ${activeComponent === 'groups-manager' ? 'active-link' : ''}`}
                                    onClick={() => handleLinkClick('groups-manager')}
                                >
                                    {activeComponent === 'groups-manager' ? '-' : ''} Groups Manager
                                </button>
                            </li>

                        </ul>
                    </nav>
                    <main className='sm-main'>
                        {!sidebarOpen &&
                            <button className="menu-btn" onClick={toggleSidebar}>
                                <BackLogo fill="white" className="userlist-back-btn rotate-180" alt="back" />
                            </button>
                        }
                        <h1 className='pt-4'>Session Manager</h1>
                        {renderComponent()}
                    </main>
                </section>
            }
        </>
    )
}

export default SessionManager