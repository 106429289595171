import React from 'react'
import { Button, Modal } from 'react-bootstrap';

function PopupToast({ showPT, setShowPT, infoMsg, btnMsg }) {
    return (
        <>
            <Modal show={showPT} onHide={() => setShowPT(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body><span>{infoMsg}</span></Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-primary me-3' onClick={() => setShowPT(false)}>
                        {btnMsg}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PopupToast