import { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { StoreContext } from './context/StoreProvider';
import Home from './pages/Home';
import Login from './pages/Login';
import PageNotFound from './pages/404';
import PrivacyPolicy from './pages/PrivacyPolicy';
// import TermsandConditions from './pages/TermsandConditions';
// import Profile from './pages/Profile';

import SignUp from './components/LoginComponents/SignUp';
import Header from './components/Header';
import Footer from './components/Footer';
import SessionManager from './components/SessionComponents/SessionManager';
import ForgotPassword from './components/LoginComponents/ForgotPassword';
import JoinSession from './components/SessionComponents/JoinSession';
import HandlePresenterSession from './components/SessionComponents/HandlePresenterSession';

function App() {
	const { sections } = useContext(StoreContext);
	return (
		<Router>
			<div className={`page-wrapper d-flex flex-column ${sections}`}>
				<div className="flex-shrink-0">
					<Header section={sections} />
				</div>
				<div className="page-container">
					<div className="position-relative" style={{ zIndex: 1, height: "calc(100vh - 112px)" }}>
						<Routes>
							<Route exact path="/" element={<Home setDirectSection='home'/>} />
							{/* <Route exact path="/about-us" element={<Home setDirectSection='about'/>} /> */}
							<Route exact path="/contact-us" element={<Home setDirectSection='contact'/>} />
							<Route exact path="/instructions" element={<Home setDirectSection='instructions'/>} />
							<Route exact path="/download" element={<Home setDirectSection='downloadapp'/>} />
							<Route exact path="/login" element={<Login />} />
							<Route exact path="/sign-up" element={<SignUp />} />
							<Route exact path="/session-manager" element={<SessionManager />} />
							<Route exact path="/join-session" element={<JoinSession />} />
							<Route exact path="/join-session/:id" element={<JoinSession />} />
							<Route exact path="/presenter" element={<HandlePresenterSession />} />
							<Route exact path="/presenter/:id" element={<HandlePresenterSession />} />
							{/* <Route exact path="/profile" element={<Profile />} /> */}
							<Route exact path="/forgot-password" element={<ForgotPassword />} />
							<Route path='/privacy-policy' element={<PrivacyPolicy />} />
							{/* <Route path='/terms-and-conditions' element={<TermsandConditions />} /> */}
							<Route path='*' element={<PageNotFound />} />
						</Routes>
					</div>
				</div>

				<Footer />
			</div>
		</Router>
	);
}

export default App;