import { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import LoginWithGoogle from './LoginWithGoogle';
import PopupToast from "../HelperComponents/PopupToast";

const userErrorMsg = 'Failed User Creation!, Please try again after some time.';
const passErrorMsg = 'Password must be at least 6 characters long.';
const emailErrorMsg = 'Please enter a valid email address.';

const SignUp = () => {
	const { createUser, user, loading, UpdateUserInDB } = useContext(AuthContext);
	const navigate = useNavigate();
	const [showPT, setShowPT] = useState(false);
	const [infoMsg, setInfoMsg] = useState(userErrorMsg);

	// If authentication is still loading, display a loading indicator
	if (loading) {
		return (
			<span className="loading loading-dots loading-lg flex item-center mx-auto"></span>
		);
	}

	// If the user is already authenticated, redirect to the home page
	if (user) {
		navigate("/");
	}

	// Handle form submission for user registration
	const handleFormSubmit = (e) => {
		e.preventDefault();
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		const name = e.target.name.value;
		const email = e.target.email.value;
		const password = e.target.password.value;

		if (password.length < 8) {
			setInfoMsg(passErrorMsg);
			setShowPT(true);
			return;
		}

		if (!emailRegex.test(email)) {
			setInfoMsg(emailErrorMsg);
			setShowPT(true);
			return; 
		}

		createUser(email, password)
			.then((result) => {
				// Update user profile with display name
				updateProfile(result.user, {
					displayName: name,
				});
				if(UpdateUserInDB(result.user, name)){
					navigate("/");
				} else {
					setShowPT(true)
				}
			})
			.catch((error) => {
				console.error(error);
				setShowPT(true)
			});
		e.target.reset();
	};

	// Render the sign-up form
	return (
		<>
			<section className="pageModal login-section">
				<div className="container h-100">
					<div className="row  align-items-center h-100">
						<div className="col-12 ">
							<div className="p5 m-auto d-flex flex-column align-items-center">
								<div className="card-body login-card">
									<div>
										<h3>Create a new account</h3>
									</div>
									<div style={{ height: '10px' }}></div>
									<form onSubmit={handleFormSubmit}>
										<div className="mb-3 form-box">
											<input
												type="text"
												name="name"
												placeholder="Full name"
												className="form-control"
												required
											/>
										</div>
										<div className="mb-3 form-box">
											<input
												type="email"
												name="email"
												placeholder="Email"
												className="form-control"
												required
											/>
										</div>
										<div className="mb-3 form-box">
											<input
												type="password"
												name="password"
												placeholder="Password"
												className="form-control"
												required
											/>
										</div>
										<div className="form-control mt-6 login-submit">
											<Button type="submit" className="btn btn-primary form-box">Sign Up</Button>
										</div>
									</form>
									<LoginWithGoogle />
									<p className="text-center register-test mt-3">Already have an account? <span onClick={() => navigate('/login')} className="text-decoration-none link-col">Login here</span></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<PopupToast showPT={showPT} setShowPT={setShowPT} infoMsg={infoMsg} btnMsg='Continue'/>
		</>
	);
};

export default SignUp;