import React, { useState, useEffect, useMemo } from "react";
import { onSnapshot, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import UserSidebarUser from './UserSidebarUser'
import back_logo from "../../assets/left_sq_bracket.svg";

const UserSidebar = ({ docId, isUserSBOpen, ToggleUserSidebar }) => {
    const [userList, setUserList] = useState(null);
    const [userListError, setUserListError] = useState('');

    const sortedUsList = useMemo(
        () => (userList) ? Object.fromEntries(
            Object.entries(userList).sort(([a], [b]) => b.localeCompare(a)).map(([k, v]) => [k, v])
        ) : {}, [userList]
    )

    const uslist = useMemo(
        () => (sortedUsList) ? Object.keys(sortedUsList).map((key) => [key, sortedUsList[key]]) : []
        , [sortedUsList]
    )

    const updateUserStatus = (xuser, changeTo) => {

        const docRef = doc(db, "end_users", docId);
        let listUser = {};

        getDoc(docRef).then(docSnap => {
            if (docSnap.exists()) {
                listUser = docSnap.data();

                let approved = listUser.approved;
                let pending = listUser.pending;
                let denied = listUser.denied
                let updateflag = false;

                let index = pending.indexOf(xuser);

                if (index === -1) {
                    index = denied.indexOf(xuser)

                    if (index === -1) {
                        index = approved.indexOf(xuser)

                        if (index !== -1) {
                            approved.splice(index, 1);
                            updateflag = true;
                        }

                    } else {
                        denied.splice(index, 1);
                        updateflag = true;
                    }
                } else {
                    pending.splice(index, 1);
                    updateflag = true;
                }

                if (updateflag) {

                    if (changeTo === 'approved') {
                        approved.push(xuser);
                    } else if (changeTo === 'denied') {
                        denied.push(xuser);
                    }

                    updateDoc(doc(db, "end_users", docId), {
                        "pending": pending,
                        "approved": approved,
                        "denied": denied
                    });
                }
            }
        });
    };

    useEffect(() => {

        const unsub = onSnapshot(doc(db, "end_users", docId), { source: 'server' }, (docData) => {
            console.log('users(list) snapshot triggered')
            if (docData.exists) {
                setUserListError('')
                setUserList(docData.data())
            } else {
                setUserListError('Error fetching user list')
            }
        });

        return () => unsub();

    }, [docId]);

    return (
        <>
            <div className={`user-sidebar ${isUserSBOpen === true ? 'active' : ''}`}>
                <div className="usd-header">
                    <h3 className="mb-0">User's List</h3>
                    <img src={back_logo} className="userlist-back-btn" alt="back" onClick={ToggleUserSidebar} />
                </div>
                <div className="usd-body">
                    {uslist ?
                        <>
                            {uslist.map((x) => (
                                <div key={x.toString()}>
                                    {x[1].length > 0 &&
                                        <div className="user-sidebar-div">
                                            <div className={"color-" + x[0]}>{x[0].charAt(0).toUpperCase() + x[0].slice(1)}</div>
                                            <hr />
                                            <ul>
                                                {x[1].map((xUser) => (
                                                    <UserSidebarUser key={xUser} xUser={xUser} xtype={x[0]} updateUserStatus={updateUserStatus}></UserSidebarUser>
                                                ))}
                                            </ul>
                                            <hr />
                                        </div>
                                    }
                                </div>
                            ))}
                        </>
                        :
                        <>
                            <span>
                                {userListError}
                            </span>
                        </>
                    }
                </div>
            </div>
            <div className={`user-sidebar-overlay ${isUserSBOpen === true ? 'active' : ''}`} onClick={ToggleUserSidebar}></div>
        </>
    )
}

export default UserSidebar;