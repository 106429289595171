import { useState, useContext } from "react";
import { AuthContext } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';

const ForgotPassword = () => {
    const { forgotPassword, user, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    // forgotpassword modal
    const [show, setShow] = useState(false);

    // If authentication is still loading, display a loading indicator
    if (loading) {
        return (
            <span className="loading loading-dots loading-lg flex item-center mx-auto"></span>
        );
    }

    // If the user is already authenticated, redirect to the home page
    if (user) {
        navigate("/");
    }

    // Handle form submission for forget password
    const handleFormSubmit = (e) => {
        e.preventDefault();
        const email = e.target.email.value;

        forgotPassword(email)
            .then(() => {
                setShow(true)
                console.log('password reset link successfully sent')
            })
            .catch((error) => {
                console.log(error);
            });
        e.target.reset();
    };

    const redirectTologin = () => {
        setShow(false)
        navigate('/login')
    }

    // Render the forgot password form
    return (
        <>
            <section className="pageModal login-section">
                <div className="container h-100">
                    <div className="row  align-items-center h-100">
                        <div className="col-12 ">
                            <div className="p5 m-auto d-flex flex-column align-items-center">
                                <div className="card-body login-card">
                                    <div>
                                        <h3>Forgot password?</h3>
                                    </div>
                                    <div style={{ height: '10px' }}></div>
                                    <form onSubmit={handleFormSubmit}>
                                        <div className="mb-3 form-box">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                className="form-control"
                                                required
                                            />
                                        </div>
                                        <div className="form-control mt-6 login-submit">
                                            <Button className="btn btn-primary form-box">Get reset password email</Button>
                                        </div>
                                    </form>
                                    <div style={{ height: '15px' }}></div>
                                    <p className="text-center register-test mt-3">Don't have an account? <span onClick={() => navigate('/sign-up')} className="text-decoration-none link-col">Register here</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body><span>Password reset email send successfully</span></Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-primary me-3' onClick={() => redirectTologin()}>
                        Go back to login
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ForgotPassword;