import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useTable, usePagination } from "react-table";
import { Button, Modal } from "react-bootstrap";
import axios from 'axios';
import GroupUsersForm from "./GroupUsersForm";
import ScreenLoader from "../HelperComponents/ScreenLoader";

function GroupsManager({ user }) {

	const pageSize = 8;
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [editingItem, setEditingItem] = useState(null);
	const [selectedId, setSelectedId] = useState(null);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const handleDeleteModelClose = () => setShowDeleteModal(false);
	const handleDeleteModelShow = () => setShowDeleteModal(true);

	const [showNewGroupModal, setShowNewGroupModal] = useState(false);
	const handleNewGroupModelClose = () => setShowNewGroupModal(false);
	const handleNewGroupModelShow = () => setShowNewGroupModal(true);

	const updateGroup = useCallback(async (group_id, group_name, group_status) => {
		try {
			const response = await axios({
				method: 'post',
				baseURL: process.env.REACT_APP_API_BASE_URL,
				url: '/update-group',
				auth: {
					username: process.env.REACT_APP_API_USERNAME,
					password: process.env.REACT_APP_API_PASSWORD
				},
				params: {
					db_id: user.db_id,
					group_name: group_name,
					group_id: group_id,
					group_status: group_status,
				}
			});

			const respData = response.data;
			if (respData.status === 1) {
				return respData.data;  // Return the group ID or data
			} else {
				return false;  // Return false if the status is not 1
			}
		} catch (error) {
			console.error('Error updating the group:', error);
			return false;  // Return false if there's an error
		}
	}, [user]);


	const handleDelete = useCallback(async (id) => {
		// Find the current row for the given id
		const currentRow = rows.find((row) => row.id === id);

		// Return early if the row is not found
		if (!currentRow) {
			console.error("Error deleting group: Group " + id + " not found");
			return;
		}

		const response = await updateGroup(id, currentRow.group_name, 2)

		if (response) {
			setRows((rows) => rows.filter((row) => row.id !== id));
		} else {
			console.error('Error deleting the group')
		}
		handleDeleteModelClose();
	}, [updateGroup, rows]);

	const handleDeletePopup = useCallback((id) => {
		handleDeleteModelShow();
		setSelectedId(id);
	}, []);

	const handleToggleActive = useCallback(async (id) => {
		// Find the current row for the given id
		const currentRow = rows.find((row) => row.id === id);

		if (!currentRow) {
			console.error("Error updating group status: Group " + id + " not found");
			return;
		}

		const updatedStatus = (currentRow.status === 1) ? 0 : 1;

		const response = await updateGroup(id, currentRow.group_name, updatedStatus)

		if (response) {
			setRows((rows) =>
				rows.map((row) =>
					row.id === id ? { ...row, status: updatedStatus } : row
				)
			);
		} else {
			console.error('Error updating status of the group')
		}

	}, [rows, updateGroup]);

	const handleNewGroupForm = async (e) => {
		e.preventDefault();
		const name = e.target.name.value;

		try {
			const response = await updateGroup(0, name, 1);

			if (response) {
				setRows((prevRows) => [
					...prevRows,
					{ id: response, group_name: name, customer_id: user.db_id, status: 1 }
				]);

				setEditingItem(response);
				handleNewGroupModelClose();
				return true;
			} else {
				console.error('Error updating the group');
				handleNewGroupModelClose();
				return false;
			}
		} catch (error) {
			console.error('Error occurred during group update:', error);
			handleNewGroupModelClose();
			return false;
		}
	}

	const handleBack = () => {
		setEditingItem(null);
	};

	useEffect(() => {
		if (user) {
			const fetchGroupsData = async () => {
				setLoading(true);
				try {
					axios({
						method: 'get',
						baseURL: process.env.REACT_APP_API_BASE_URL,
						url: '/get-group-data',
						auth: {
							username: process.env.REACT_APP_API_USERNAME,
							password: process.env.REACT_APP_API_PASSWORD
						},
						params: {
							db_id: user.db_id
						}
					}).then(function (response) {
						const respData = response.data;
						const data = respData.data;
						if(data && data.length > 0){
							setRows(data);
							setTotalRecords(data.length);
						}
						setLoading(false);
					}).catch(function (error) {
						setLoading(false);
						console.error('Error fetching groups data:', error);
					})
				} catch (error) {
					console.error("Error fetching groups data:", error);
				} finally {
					setLoading(false);
				}
			}

			fetchGroupsData();
		}
	}, [user]);

	const columns = useMemo(
		() => [
			{
				Header: "Group Name",
				accessor: "group_name",
				Cell: ({ value }) => <div>{value}</div>,
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: ({ row }) => (
					<button
						style={{ width: "100%", textTransform: "none" }}
						className={`btn status-btn ${(row.original.status === 1) ? "active" : "inactive"}`}
						onClick={() => handleToggleActive(row.original.id)}
					>
						{(row.original.status === 1) ? "Active" : "Inactive"}
					</button>
				),
			},
			{
				Header: "Actions",
				Cell: ({ row }) => (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<button className="btn edit-btn" onClick={() => setEditingItem(row.original.id)}>
							Edit
						</button>
						<button className="btn delete-btn" onClick={() => handleDeletePopup(row.original.id)}>
							Delete
						</button>
					</div>
				),
			},
		],
		[handleToggleActive, handleDeletePopup]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		state: { pageIndex },
	} = useTable(
		{
			columns,
			data: rows,
			initialState: { pageIndex: 0, pageSize: pageSize },
		},
		usePagination
	);

	if (editingItem) {
		return <GroupUsersForm user={user} group_id={editingItem} onBack={handleBack} />;
	}

	if (loading) {
		return <ScreenLoader placeMiddle={true} />
	}

	return (
		<>
			<div>
				<p>You can also create groups which can be assigned when creating sessions, what that will do is mail all the group users that they have a session assigned to them.</p>
			</div>
			{(rows.length > 0) ?
				<>
					<div className="table-container">
						<table {...getTableProps()} className="groups-table">
							<thead>
								{headerGroups.map((headerGroup) => {
									const { key, ...headerProps } = headerGroup.getHeaderGroupProps();
									return (
										<tr key={key} {...headerProps}>
											{headerGroup.headers.map((column) => {
												const { key: colKey, ...colProps } = column.getHeaderProps();
												return (
													<th key={colKey} {...colProps}>
														{column.render("Header")}
													</th>
												);
											})}
										</tr>
									);
								})}
							</thead>
							<tbody {...getTableBodyProps()}>
								{page.map((row) => {
									prepareRow(row);
									const { key, ...rowProps } = row.getRowProps();
									return (
										<tr key={key} {...rowProps}>
											{row.cells.map((cell) => {
												const { key: cellKey, ...cellProps } = cell.getCellProps();
												return (
													<td key={cellKey} {...cellProps}>
														{cell.render("Cell")}
													</td>
												);
											})}
										</tr>
									);
								})}
							</tbody>
						</table>

						<div className="blogs-grid-btns">
							<div className="pagination-controls">
								{canPreviousPage &&
									<button className='btn btn-primary me-3' onClick={previousPage} disabled={!canPreviousPage}>
										Previous
									</button>
								}
								<span className='me-3'>
									Page {pageIndex + 1} of {Math.ceil(totalRecords / pageSize)}
								</span>
								{canNextPage &&
									<button className='btn btn-primary' onClick={() => nextPage()} disabled={!canNextPage}>
										Next
									</button>
								}
								<button className='btn btn-primary' style={{ float: 'right' }} onClick={() => handleNewGroupModelShow()}>
									Add new group
								</button>
							</div>
						</div>
					</div>

					{/* Deletion conformation model */}
					<Modal show={showDeleteModal} onHide={handleDeleteModelClose} centered>
						<Modal.Body>
							Are you sure you want to delete this group?
						</Modal.Body>
						<Modal.Footer>
							<Button className='btn btn-primary me-3' onClick={handleDeleteModelClose}>
								Close
							</Button>
							<Button variant="btn btn-primary me-3" onClick={() => handleDelete(selectedId)}>
								Yes
							</Button>
						</Modal.Footer>
					</Modal>
				</>
				:
				<>
					<div>You have not created any user groups, please click on the following "add new" button to create a group</div>
					<div className="blogs-grid-btns">
						<div className="pagination-controls">
							<button className='btn btn-primary mt-3' onClick={() => handleNewGroupModelShow()}>
								Add New
							</button>
						</div>
					</div>
				</>
			}
			{/* New form model */}
			<Modal show={showNewGroupModal} onHide={handleNewGroupModelClose} centered>
				<Modal.Body>
					<form onSubmit={handleNewGroupForm}>
						<div className="mb-3 form-box w-100">
							<input
								type="text"
								name="name"
								placeholder="Group Name"
								className="form-control"
								required
							/>
						</div>
						<div>
							<div style={{ float: "right" }}>
								<Button className="me-2" onClick={handleNewGroupModelClose}>Cancle</Button>
								<Button type="submit">Create</Button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	);
}

export default GroupsManager