import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from 'axios';

const savePageMsg = 'Are you sure you want to save this page?'
const deletePageMsg = 'Are you sure you want to delete this page?'

const PageUpdate = ({ type, pageUrl, docId, pageId, setDDUpdateFlag }) => {

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [pageName, setPageName] = useState('');

    const toggleModelClose = () => setShow(false);
    const toggleModelShow = () => setShow(true);

    const handlePageUpdate = () => {

        setLoading(true);
        axios({
            method: 'post',
            baseURL: process.env.REACT_APP_API_BASE_URL,
            url: '/update-page',
            auth: {
                username: process.env.REACT_APP_API_USERNAME,
                password: process.env.REACT_APP_API_PASSWORD
            },
            params: {
                status: (type === 'save') ? 1 : 2,
                page_url: pageUrl,
                sess_id: docId,
                page_id: pageId,
                page_name: pageName
            }
        }).then(function (response) {
            setLoading(false);
            setDDUpdateFlag(true);
            toggleModelClose();
        }).catch(function (error) {
            setLoading(false);
            setDDUpdateFlag(true);
            toggleModelClose();
            console.error('Error updating the page:', error);
        })
    }

    return (
        <>
            <Modal show={show} onHide={() => toggleModelClose()} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="mb-4">{type === 'save' ? savePageMsg : deletePageMsg }</div>
                    { type === 'save' &&
                        <>
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Page Name"
                                    className="form-control"
                                    value={pageName}
                                    onChange={(e) => setPageName(e.target.value)}
                                    required
                                />
                            </div>
                        </>
                    }
                    </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-primary me-3' onClick={() => toggleModelClose()}>
                        Close
                    </Button>
                    <Button className='btn btn-primary me-3' onClick={() => handlePageUpdate()}>
                        {type}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Button className="btn-primary-reverse page-save-btn" onClick={() => toggleModelShow()} disabled={loading}>
                {loading ? `wait..` : type}
            </Button>
        </>
    );
}

export default PageUpdate;