import { useState, createContext } from "react";
import PropTypes from "prop-types";
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase-config';
import axios from "axios";

export const StoreContext = createContext(null);

const StoreProvider = ({ children }) => {

    const [sections, setSections] = useState('home')

    const validateSession = async (sessId) => {
        try {
            // Make the axios request
            const responseDB = await axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: '/get-session-data',
                auth: {
                    username: process.env.REACT_APP_API_USERNAME,
                    password: process.env.REACT_APP_API_PASSWORD
                },
                params: {
                    sess_id: sessId,
                },
            });
    
            const respDBData = responseDB.data;
            const sessDBData = respDBData.data;
    
            // Validate with Firebase
            const joinRef = doc(db, "board_session", sessId);
            const docSnap = await getDoc(joinRef);
    
            if (docSnap.exists()) {
                return { success: true, data: sessDBData };
            } else {
                return { success: false, error: "Invalid Session Key - Document doesn't exist on Firebase" };
            }
        } catch (e) {
            return { success: false, error: e };
        }
    };

    const handlePDFDownload = async (DocId, imgUrl) => {
		try {
			const response = await axios({
                method: 'get',
                baseURL: process.env.REACT_APP_API_BASE_URL,
                url: '/download-pdf',
                responseType: 'blob',
                auth: {
                    username: process.env.REACT_APP_API_USERNAME,
                    password: process.env.REACT_APP_API_PASSWORD
                },
                params: {
                    sess_id: DocId,
                    img_url: imgUrl,
                },
			});
			const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            return { success: true, data: pdfBlob };
		} catch (error) {
			console.error('Error downloading the PDF:', error);
            return false;
		}
	};

    const StoreValue = {
        sections,
        setSections,
        validateSession,
        handlePDFDownload,
    };

    return <StoreContext.Provider value={StoreValue}>{children}</StoreContext.Provider>;
};

StoreProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default StoreProvider;