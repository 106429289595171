import { Link } from 'react-router-dom';

const Footer = () => {

    return (
        <>
            <footer className="footer mt-auto py-2 bg-light">
                <div className="container">
                    <div>
                        <span className="copyright-text footer-fonts">© 2024 sushboard</span>
                        <div className="float-end ">
                            <Link className="footer-links me-3" to='/privacy-policy'>Privacy Policy</Link>
                            {/* <Link className="footer-links" to='/terms-and-conditions'>Terms & Conditions</Link> */}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;