import { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { StoreContext } from "../../context/StoreProvider";
import { AuthContext } from "../../context/AuthProvider";
import PresenterBoard from "./PresenterBoard";
import ScreenLoader from "../HelperComponents/ScreenLoader";

const unauthenticatedMsg = 'You are not athenticated to access this recource or this resource does not exist!';
const invalidMsg = "The entered session key is invalid";

const HandlePresenterSession = () => {
    const { validateSession } = useContext(StoreContext);
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const params = useParams();
    const paramId = params.id;
    const [error, setError] = useState(null);
    const [sessionData, setSessionData] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showLoader, setShowLoader] = useState(true);

    if(!paramId){
        navigate('/join-session');
    }

    if(!user){
        navigate('/login');
    }

    useEffect(() => {
        if (paramId && user) {
            const validate = async () => {
                const response = await validateSession(paramId);
                if (!response.success) {
                    setError(invalidMsg);
                    setShowLoader(false);
                } else {
                    const sessData = response.data;
                    if(sessData.cust_id === user.db_id){
                        setIsAdmin(true);
                        setSessionData(sessData);
                        setError(null);
                    } else {
                        setError(unauthenticatedMsg);
                    }
                    setShowLoader(false);
                }
            };
            validate();
        }
    }, [user, paramId, validateSession]);

    return ( showLoader ?
        <>
            <ScreenLoader placeMiddle={true}/>
        </>
        :
        <>
            { isAdmin ?
                <>
                {error ? 
                    <>
                        <div className="info-toast top-50 start-50 translate-middle">
                            <h3>{error}</h3>
                            <span style={{ color: "#842029", cursor: "pointer" }} onClick={() => navigate('/join-session')}>Click here to go back</span>
                        </div>
                    </> 
                    :
                    <section className="pageModal presenter-section">
                        <div className="container h-100">
                            <div className="row align-items-center h-100">
                                <div className="col-12 ">
                                    <PresenterBoard sessionData={sessionData}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    }
                </>
                :
                <>
                    <div className="info-toast top-50 start-50 translate-middle">
                        <h3>{unauthenticatedMsg}</h3>
                        <span style={{ color: "#842029", cursor: "pointer" }} onClick={() => navigate('/join-session')}>Click here to go back</span>
                    </div>
                </>
            }
        </>
    );
}

export default HandlePresenterSession;
