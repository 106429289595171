import React from 'react'

function PageHeader({sessName}) {
  return (
    <div className="header-bar container">
        <span>{sessName}</span>
    </div>
  )
}

export default PageHeader