import React from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import min_fs_logo from "../../assets/minimize_fs.svg";
import max_fs_logo from "../../assets/maximize_fs.svg";

function PageView({ imgUrl, isPause, canFS }) {

    const handle = useFullScreenHandle();

    const fullscreenToggle = () => {
        if (handle.active) {
            handle.exit();
        } else {
            handle.enter();
        }
    };

    return (
        <FullScreen handle={handle}>
            {imgUrl &&
                <div className="container">
                    <div className="row">
                        <div className="col-12 position-relative p-0">
                            {isPause &&
                                <div className="info-toast info-toast-bottom">
                                    <p>Broadcast paused by the caster!</p>
                                </div>
                            }
                            {canFS && 
                                <div className="fullscreen-action" onClick={fullscreenToggle}>
                                    <img
                                        src={handle.active ? min_fs_logo : max_fs_logo}
                                        className="plusActionBtn"
                                        alt={handle.active ? `minimize` : `maximize`}
                                    />
                                </div>
                            }
                            <img
                                src={imgUrl}
                                style={{ height: (handle.active ? `100vh` : `75vh`), opacity: (isPause ? 0.5 : 1), transition: "all 0.25s linear 0s" }}
                                alt="board"
                                className="w-100"
                            />
                        </div>
                    </div>
                </div>
            }
        </ FullScreen>
    )
}

export default PageView