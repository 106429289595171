import React from 'react'

function CreateColabSession() {
  return (
    <div>
      <p>In collaborative session there is an admin who creates the session and many broadcasters who are also listners who can watch/subscribe to what the admin is broadcasting(admin can also broadcast other broadcasters content), Admin can see all the broadcasters feed.</p>
      <p>Collaboration session feature comming soon!</p>
      </div>
  )
}

export default CreateColabSession